export function routes({ configroutes, defaultRootAction, requests, name }) {

    const request = requests[name]()
    const userRequest = requests['measuser']()

    return [
        {
            path: configroutes['menu' + name + 'slug'] + '/edit',
            data: {
                content: name + 'edit',
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'] + '/edit',
                        content: name + 'edit',
                        disablefetchcall: true,
                        fetchreq: request
                    }
                }
            ]
        }, {
            path: configroutes['menu' + name + 'slug'] + '/new',
            data: {
                content: name + 'new',
                fetchreq: userRequest
            }
        }, {
            path: configroutes['menu' + name + 'slug'],
            data: {
                content: name,
                fetchreq: request,
                disablefetchcall: true,
                callback: defaultRootAction
            },
            children: [
                {
                    path: '/:' + name,
                    data: {
                        startroute: configroutes['menu' + name + 'slug'],
                        content: name,
                        disablefetchcall: true,
                        fetchreq: request
                    }
                }
            ]
        }
    ]
}
