export default {
    postposttitle: 'Post',
    menuposttitle: 'Post',
    editposttitle: 'Edit post',
    newposttitle: 'New post',
    userpostssubtitle: 'Posts',
    userpostsstrashsubtitle: 'Deleted posts',
    wapplruserapppostsname: 'Posts',
    wapplruserapppoststitle: 'User posts',
    wapplruserapppoststrash: 'Deleted posts',

    steppostbusinesstype: 'Business type',
    steppfferposition: 'Position',
    steppostbudgetanddata: 'More data',
    steppostchooseacover: 'Cover',
    steppostgallery: 'Add images',

    wordbusinessrating: 'Rating',
    wordtypeofbusiness: 'Type of business',
    selecttypeofbusiness: 'Select your business type',
    wordtype: 'type',
    wordtypes: 'types',

    wordposition: 'Position',
    wordexperience: 'Experience',
    wordprofessiongroup: 'Profession category',
    selectpositionandexperience: 'Select position and experience',
    selectposition: 'Select position',

    postdescription: 'Post description',
    postdescriptioninfo: function(max) {
        return 'What we are looking for... (Max ' + max + ' characters)'
    },

    additionalinformation: 'Additional information',
    additionalinformationinfo: function(max) {
        return 'Additional information about post (How to get there / Menu ...) (Max ' + max + ' characters)'
    },

    postsearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Search in posts'
        if (username && !taxs) r = 'Search in ' + username + '\'s posts'
        if (!username && taxs) r = 'Search in posts in ' + taxs + ' category'
        if (username && taxs) r = 'Search in ' + username + '\'s posts, and in ' + taxs + ' category'
        return r
    },

    edithinttitle: 'Title',
    edithintsubtitle: 'Subtitle',
    edithintcontentbrief: 'Content brief',
    edithintcontentextended: 'Content',
    edithintpostgallery: 'Post gallery',
    edithintcover: 'Cover',
    editlabelpubliccontent: 'Public content'

}
