import React from 'react'

import usergalleryhelpers from '../../../components/Usergallery/helpers'
import fetchs from '../../../services/fetchs'
import SelectGroup from '../../../components/SelectGroup/formsy'
import sgs from '../../../components/SelectGroup/SelectGroup.css'

function strip(html) {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    return tmp.textContent || tmp.innerText || ''
}

const step1min = 0

export function reqursiveSearchTaxonomy({ tax, id }) {
    let foundtax = null
    if (tax && tax.items) {
        tax.items.map(function(t) {
            if (!foundtax && t.id === id) foundtax = t
            if (!foundtax && t.items) {
                foundtax = reqursiveSearchTaxonomy({ tax: t, id })
            }
        })
    }
    return foundtax
}


export function getSelectDataFromTaxonomy({
                                              name,
                                              taxonomies = {},
                                              taxonomyname,
                                              parent,
                                              selectattr,
                                              disableAddEmpty
                                          }) {

    const rname = (name) ? name : taxonomyname
    const r = {
        name: rname,
        selectattr: selectattr,
        data: []
    }
    let foundtax = null
    Object.keys(taxonomies).map(function(key) {
        const tax = taxonomies[key]
        if (!foundtax && tax.name === taxonomyname) foundtax = tax
    })
    if (parent) {
        foundtax = reqursiveSearchTaxonomy({ tax: foundtax, id: parent })
    }
    const data = []
    if (foundtax && foundtax.items) {
        r.id = foundtax.id
        if (!disableAddEmpty) data.push({ name: '', value: null })
        foundtax.items.map(function(t) {
            data.push({ name: t.name, value: t.id })
        })
    }
    r.data = data
    return r
}

export function getSubSelectFunctionBySubCategory({
                                                      name = 'Sub category',
                                                      parentcolumn = 0,
                                                      parentdata = { id: 0, data: [] },
                                                      taxonomies = {},
                                                      taxonomyname
                                                  }) {
    return function({ state, currentrow, currentcolumn }) {

        const { values = [] } = state
        const currentvalue = (values[currentrow] && values[currentrow][parentcolumn]) ? values[currentrow][parentcolumn] : null
        let value = (values[currentrow] && values[currentrow][currentcolumn]) ? values[currentrow][currentcolumn] : null
        let found = 0
        const r = {
            name: name,
            data: []
        }

        parentdata.data.map(function(d) {
            if (!found && d.value && currentvalue === d.value) {
                r.data = [
                    ...getSelectDataFromTaxonomy({ name, taxonomies, taxonomyname, parent: currentvalue }).data
                ]
                let foundvalue = false
                r.data.map(function(rd) {
                    if (value === rd.value) foundvalue = true
                })
                if (!foundvalue) value = null
                found = 1
            }
        })

        if (!found) {
            if (values[currentrow][currentcolumn] !== null) values[currentrow][currentcolumn] = null
        } else {
            if (values[currentrow][currentcolumn] !== value) values[currentrow][currentcolumn] = value
        }
        return r

    }
}

function selectGroupValidation({ refs, onValid, onFail, validations = {}, data }) {

    let valid = true
    let errorMessage = null
    let invalidKey = null
    let failElem = null
    let values = null

    if (refs.elements) {
        Object.keys(refs.elements).map(function(key) {
            if (refs.elements[key] && refs.elements[key].props && refs.elements[key].props.endvalidation && valid) {
                const sg = refs.elements[key]
                const name = sg.props.name
                if (sg.getValues && name) {

                    const sgs = sg.getValues()
                    if (!values) values = {}
                    values[name] = sgs

                    Object.keys(validations).map(function(vkey) {
                        if (valid) {
                            const validanddata = validations[vkey].validation({
                                ...validations[vkey].props,
                                value: values[name]
                            })
                            valid = validanddata[0]
                            if (valid) values[name] = validanddata[1]
                            if (!valid) {
                                errorMessage = validations[vkey].errorMessage
                                invalidKey = key
                                failElem = sg
                            }
                        }
                    })
                }
            }
        })
    }

    if (valid) {
        if (!values) values = data
        if (onValid) onValid(values)
    } else {
        if (onFail) onFail({ element: failElem, errorMessage, invalidKey })
    }
}

export function endValidationHelpers({ stepIndex, onValid, onFail, data, resetForm, invalidateForm, refs, curlang }) {



    selectGroupValidation({
        refs: refs,
        data: data,
        onValid: function(data) {
            onValid(data, resetForm, invalidateForm, refs)
        },
        onFail: function({ errorMessage, invalidKey }) {
            setTimeout(function() {
                onFail({ [invalidKey]: errorMessage })
            })
        },
        validations: {
            step1: {
                validation: function({ value, n}) {
                    if (stepIndex === 0) {
                        const v = []
                        let hm = 0
                        value.map(function(row) {
                            if (row[0]) {
                                hm = hm + 1
                                v.push(row)
                            }
                        })
                        return [(hm > n), v]
                    }
                    return [true, value]
                },
                props: {
                    n: step1min - 1
                },
                errorMessage: curlang.minselectnumber(step1min)
            }
        }
    })

}

export function editdata({ curlang, history, searchData, posttypesData, routes, uploadconfig, getthumb, taxonomies }) {

    const {
        wordcategory = 'category'
    } = curlang

    const categorydata = getSelectDataFromTaxonomy({ taxonomies, taxonomyname: 'Category', name: wordcategory })

    return {
        0: [
            {
                type: 'selectgroup',
                component: SelectGroup,
                attr: {
                    title: wordcategory,
                    name: 'category',
                    endvalidation: true,
                    style: function() {
                        return sgs
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.category) ? post.category : []
                    },
                    onChange: function(state, props) {
                        const { post } = props
                        return function({ data, setState, state, compSetState }) {
                            const values = (data && data.length) ? data : []
                            const save = []
                            values.map(function(v) {
                                if (v[0]) save.push(v)
                            })
                            if (post) {
                                if (save.length > (step1min - 1)) {
                                    post.category = save
                                } else {
                                    post.category = values
                                }
                            }
                            compSetState({
                                values: values
                            })
                            if (save.length > (step1min - 1) && !state._isValid) {
                                setState({
                                    _isValid: true,
                                    _externalError: [],
                                    _value: values
                                })
                            }
                        }
                    },
                    column: [
                        categorydata
                    ],
                    row: [
                        { name: 'Most important 1' },
                        { name: 'Most important 2' },
                        { name: 'Most important 3' }
                    ],
                    responsiveColumns: {
                        800: 1,
                        480: 1
                    }
                }
            }, {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function() {

                        return curlang.next || 'next'
                    },
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { marginTop: 0 }
                        return { marginTop: 32 }
                    },
                    disabled: function(state = {}) {
                        return !state.canSubmit
                    }
                }
            }
        ],
        1: [
            {
                type: 'text',
                attr: {
                    name: 'title',
                    hintText: curlang.edithinttitle,
                    floatingLabelText: curlang.edithinttitle,
                    validations: {
                        minLength: 2,
                        maxLength: 200
                    },
                    validationErrors: {
                        minLength: curlang.mincharerror(2),
                        maxLength: curlang.maxcharerror(200)
                    },
                    style: { width: '100%' },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post.title = value
                        }
                    },
                    required: true,
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.title) ? post.title : ''
                    }
                }
            },
            {
                type: 'text',
                attr: {
                    name: 'subtitle',
                    hintText: curlang.edithintsubtitle,
                    floatingLabelText: curlang.edithintsubtitle,
                    validations: { maxLength: 200 },
                    validationError: curlang.maxcharerror(200),
                    style: { width: '100%' },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['subtitle'] = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.subtitle) ? post.subtitle : ''
                    }
                }
            }, {
                type: 'text',
                attr: {
                    name: 'content_brief',
                    hintText: curlang.edithintcontentbrief,
                    floatingLabelText: curlang.edithintcontentbrief,
                    validations: { maxLength: 1000 },
                    validationError: curlang.maxcharerror(1000),
                    rows: 1,
                    rowsMax: 5,
                    style: { width: '100%' },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['content_brief'] = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post['content_brief']) ? strip(post['content_brief']) : ''
                    }
                }
            }, {
                type: 'text',
                attr: {
                    name: 'content_extended',
                    hintText: curlang.edithintcontentextended,
                    floatingLabelText: curlang.edithintcontentextended,
                    validations: { maxLength: 90000 },
                    validationError: curlang.maxcharerror(90000),
                    multiLine: true,
                    rows: 5,
                    rowsMax: 100,
                    style: { width: '100%' },
                    onChange: function(state, props) {
                        return function(e, value) {
                            const { post } = props
                            post['content_extended'] = value
                        }
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post['content_extended']) ? post['content_extended'] : ''
                    }
                }
            }, {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function() {

                        return curlang.next || 'next'
                    },
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { marginTop: 0 }
                        return { marginTop: 32 }
                    },
                    disabled: function(state = {}) {

                        return !state.canSubmit
                    }
                }
            }
        ],
        2: [
            usergalleryhelpers({
                fetchs, curlang, searchData, posttypesData, history, routes, uploadconfig, getthumb,
                config: {
                    formname: 'gallery',
                    validations: {},
                    validationErrors: {},
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.gallery) ? post.gallery : []
                    },

                    selectsimple: false,
                    title: curlang.edithintpostgallery,
                    getSelectedPosts: function({ post }) {
                        return (post && post.gallery) ? post.gallery : []
                    },
                    afterSelect: function({ e, selectedposts, setPosts, onChange, post }) {
                        const gallery = (selectedposts && selectedposts.length) ? selectedposts : []
                        if (gallery && gallery.length) {
                            post.gallery = gallery
                            setPosts({ posts: gallery, modalclose: true })
                            if (onChange) onChange(e, gallery)
                        } else {
                            post.gallery = []
                            setPosts({ posts: [], modalclose: true })
                            if (onChange) onChange(e, [])
                        }
                    },
                    afterUpload: function({ response, setPosts, onChange, post, selectedposts = [] }) {
                        let images = []

                        if (response) {
                            if (response.length) {
                                response.map(function(i) {
                                    if (i.success) {
                                        if (i.post) images.push(i.post)
                                    }
                                })
                            } else {
                                if (response.success) {
                                    if (response.post) images.push(response.post)
                                }
                            }
                        }

                        if (images) {
                            const mergedImages = [...images, ...selectedposts]
                            post.gallery = mergedImages
                            setPosts({ posts: mergedImages, modalclose: true })
                            if (onChange) onChange(null, mergedImages)
                        }
                    },
                    deleteFunction: function({ newposts, post }) {
                        const gallery = (newposts && newposts.length) ? newposts : []
                        if (gallery) {
                            post.gallery = gallery
                        } else {
                            post.gallery = []
                        }
                    },
                    disableupload: function({ props }) {
                        const { me, post } = props
                        if (me && me.id && post && post.id && post.author && post.author.id === me.id) return false
                        return !(post && post.id === 1)

                    }
                }
            }),
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function() {

                        return curlang.next || 'next'
                    },
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { marginTop: 0 }
                        return { marginTop: 32 }
                    },
                    disabled: function(state = {}) {

                        return !state.canSubmit
                    }
                }
            }
        ],
        3: [
            usergalleryhelpers({
                fetchs, curlang, searchData, posttypesData, history, routes, uploadconfig, getthumb,
                config: {
                    formname: 'coverrel',
                    validations: {
                        maxLength: 1
                    },
                    validationErrors: {
                        maxLength: curlang.maxcharerror(1)
                    },
                    value: function(state, props) {
                        const { post } = props
                        return (post && post.coverrel) ? [post.coverrel] : []
                    },

                    selectsimple: true,
                    title: curlang.edithintcover,
                    getSelectedPosts: function({ post }) {
                        return (post && post.coverrel) ? [post.coverrel] : []
                    },
                    afterSelect: function({ e, selectedposts, setPosts, onChange, post }) {
                        const cover = (selectedposts && selectedposts.length) ? selectedposts[selectedposts.length - 1] : null
                        if (cover) {
                            post.coverrel = cover
                            post.cover = cover.cover
                            setPosts({ posts: [cover], modalclose: true })
                            if (onChange) onChange(e, [cover])
                        } else {
                            post.coverrel = null
                            post.cover = null
                            setPosts({ posts: [], modalclose: true })
                            if (onChange) onChange(e, [])
                        }
                    },
                    afterUpload: function({ response, setPosts, onChange, post }) {

                        let cover = null

                        if (response) {
                            if (response.length) {
                                let wassucc = false
                                response.map(function(i) {
                                    if (i.success) {
                                        cover = i
                                        if (i.post) cover = i.post
                                        wassucc = true
                                    }
                                })
                            } else {
                                if (response.success) {
                                    cover = response
                                    if (response.post) cover = response.post
                                }
                            }
                        }

                        if (cover) {
                            post.coverrel = cover
                            post.cover = cover.cover
                            setPosts({ posts: [cover], modalclose: true })
                            if (onChange) onChange(null, [cover])
                        }

                    },
                    deleteFunction: function({ newposts, post }) {
                        const cover = (newposts && newposts.length) ? newposts[newposts.length - 1] : null
                        if (cover) {
                            post.coverrel = cover
                            post.cover = cover.cover
                        } else {
                            post.coverrel = null
                            post.cover = null
                        }
                    },
                    disableupload: function({ props }) {
                        const { me, post } = props
                        if (me && me.id && post && post.id && post.author && post.author.id === me.id) return false
                        return !(post && post.id === 1)

                    }
                }
            }),
            {
                type: 'button',
                attr: {
                    type: 'submit',
                    name: 'submit',
                    label: function(state, props) {
                        const { restoretext } = props
                        return (state.processing) ? curlang.savepost : (restoretext) ? restoretext : curlang.savepost
                    },
                    style: function(state, props) {
                        const { hiddencontent } = props
                        if (hiddencontent) return { marginTop: 0 }
                        return { marginTop: 32 }
                    },
                    disabled: function(state = {}) {

                        return !state.canSubmit
                    }
                }
            }
        ]
    }
}
