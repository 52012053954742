export default {
    postposttitle: 'Bejegyzés',
    menuposttitle: 'Bejegyzés',
    editposttitle: 'Bejegyzés szerkesztése',
    newposttitle: 'Új bejegyzés',
    userpostssubtitle: 'Bejegyzések',
    userpostsstrashsubtitle: 'Törölt bejegyzések',
    wapplruserapppostsname: 'Bejegyzések',
    wapplruserapppoststitle: 'Felhasználó bejegyzései',
    wapplruserapppoststrash: 'Törölt bejegyzések',

    steppostbusinesstype: 'Üzlet típusa',
    steppfferposition: 'Pozíció',
    steppostbudgetanddata: 'További adatok',
    steppostchooseacover: 'Borítókép',
    steppostgallery: 'Képek hozzáadása',

    wordbusinessrating: 'Üzlet szintje',
    wordtypeofbusiness: 'Üzlet típusa',
    selecttypeofbusiness: 'Válaszd ki az üzlet típusát',
    wordtype: 'típus',
    wordtypes: 'típusok',

    wordposition: 'Pozíció',
    wordexperience: 'Tapasztalat',
    wordprofessiongroup: 'Szakmai csoport',
    selectpositionandexperience: 'Pozíció és tapasztalat kiválasztása',
    selectposition: 'Pozíció kiválasztása',

    postdescription: 'Munka leírása',
    postdescriptioninfo: function(max) {
        return 'Akit keresünk... (Max ' + max + ' karakter)'
    },

    additionalinformation: 'Bővebb információ',
    additionalinformationinfo: function(max) {
        return 'Bővebb információ a munkáról (Odajutás / Menu ...) (Max ' + max + ' karakter)'
    },

    postsearchhint: function({ user, taxs }) {
        const username = (user && user.name && user.name.first) ? user.name.first : null
        let r = 'Keresés a bejegyzések között'
        if (username && !taxs) r = 'Keresés ' + username + ' bejegyzései között'
        if (!username && taxs) r = 'Keresés a bejegyzései között a ' + taxs + ' kategóriában'
        if (username && taxs) r = 'Keresés ' + username + ' bejegyzései között, a ' + taxs + ' kategóriában'
        return r
    },

    edithinttitle: 'Cím',
    edithintsubtitle: 'Alcím',
    edithintcontentbrief: 'Rövid tartalom',
    edithintcontentextended: 'Tartalom',
    edithintpostgallery: 'Galéria',
    edithintcover: 'Borító kép',
    editlabelpubliccontent: 'Publikus tartalom'

}
