import React from 'react'
import createReactClass from 'create-react-class'

import wapplrcomponents from '../../../../components'

import TemplateDefault from '../../../templates/template_default'
import avs from '../../../../themes/components/Wapplravatar.css'
import gs from '../../../../themes/components/Wapplrgallery.css'
import ps from '../../../../themes/components/Wapplrpost.css'

import PublicIcon from 'material-ui/svg-icons/social/public'
import PrivateIcon from 'material-ui/svg-icons/action/lock-outline'
import Dialog from 'material-ui/Dialog'
import FlatButton from 'material-ui/FlatButton'

import { getPostStatusData } from '../../../server/poststatus/statuses'
import imageContent from '../../../posttypes/image/contents/content'
import Slider from '../../../components/Slider'

const Wapplrpost = wapplrcomponents.post
const Wapplrgallery = wapplrcomponents.gallery

const SliderParent = createReactClass({
    render: function() {
        const { children } = this.props
        return (
            <div>
                {children}
            </div>
        )
    }
})

export default createReactClass({
    getInitialState: function() {
        this.refElements = {}

        const { response } = this.props
        const post = this.getPost(response)

        return {
            isMounted: true,
            post: post
        }
    },
    getPost: function(response) {
        return (response && response.currentpost && response.currentpost[0]) ? response.currentpost[0] : null
    },
    getPostEditLink: function() {
        const post = this.state.post
        if (post && post.id) {
            return '/post/edit/' + post.id
        }
        return ''
    },
    getHelpers: function() {
        const { helpers } = this.props

        const fetch = (helpers) ? helpers.fetch : null
        const store = (helpers.store) ? helpers.store : null
        const states = (helpers.states) ? helpers.states : null
        const setResponse = (states && states.actions && states.actions.setResponse) ? states.actions.setResponse : null
        const state = (store && store.getState) ? store.getState() : null

        return { fetch, store, state, states, setResponse }

    },
    getGlobalState: function() {
        const { state } = this.getHelpers()
        return state
    },
    getMe: function() {
        const state = this.getGlobalState()
        return (state && state.response && state.response.me) ? state.response.me : null
    },
    getPostStatus: function() {
        const { curlang } = this.props
        const post = this.state.post
        return getPostStatusData({ post, curlang })
    },

    //Publicicon
    publicicon: function({ post }) {

        const share = post && post.share

        this.getMe()


        if (share === 'public' || !share) {
            return <PublicIcon />
        }
        if (share === 'private') {
            return <PrivateIcon />
        }
        return null
    },
    openGallery: function(e, post) {

        const refElements = this.refElements
        const wapplrgallery = refElements['wapplrgallery']
        if (wapplrgallery && wapplrgallery.modalopen) {
            wapplrgallery.modalopen()
        }

        setTimeout(function() {
            const slider = refElements['slider']
            if (slider && slider.setById) {
                slider.setById(post.id)
            }
        })
    },
    getModal: function({ state, modalclose, style, setRef}) {

        const { curlang } = this.props
        const {
            closetext = 'Close'
        } = curlang
        const post = this.state.post
        const postProps = this.props

        const actions = [
            <FlatButton
                label={closetext}
                onTouchTap={modalclose}
            />
        ]

        if (post && post.gallery && post.gallery.length) {

            const slides = []
            post.gallery.map(function(image) {
                const s = {
                    component: imageContent,
                    props: {
                        ...postProps,
                        Parent: SliderParent,
                        response: {
                            currentimage: [
                                image
                            ]
                        }
                    },
                    id: image.id
                }
                slides.push(s)
            })

            return (
                <Dialog
                    ref={(setRef) ? function(e) {
                        setRef('dialog', e)
                    } : null}
                    title={post.title}
                    modal={false}
                    actions={actions}
                    open={state.modalopen}
                    onRequestClose={modalclose}
                    autoScrollBodyContent={true}
                    className={style.gallerymodal}
                >
                    <Slider slides={slides} setRef={setRef} />
                </Dialog>
            )
        } else {
            return null
        }
    },

    setRef: function(a, e) {
        const { setRef } = this.props
        this.refElements[a] = e
        if (setRef) setRef(a, e)
    },
    getContent: function(content) {
        const { companyData, curlang } = this.props
        if (content && companyData) {
            Object.keys(companyData).map(function(key) {
                const replace = '{' + key + '}'
                if (content.match(replace)) {
                    const re = new RegExp(replace, 'g')
                    let t = (companyData[key].toString) ? companyData[key].toString() : companyData[key]
                    if (key === 'vatRate') {
                        t = curlang.vatRateText({ companyData })
                    }
                    content = content.replace(re, t)
                }
            })
            return content
        }
        return ''
    },
    render: function() {

        const { history, getthumb, searchData, Parent = TemplateDefault, getthumbstyle } = this.props
        const post = this.state.post
        const author = (post && post.author) ? post.author : null
        const me = this.getMe()
        const canEdit = (author && author.id && me && me.id === author.id || me && me.isAdmin || me && me.isEditor)
        const edit = canEdit ? this.getPostEditLink() : null
        const { statusname, deleted } = this.getPostStatus()
        const publiciconcontent = this.publicicon({ post })
        const openGallery = this.openGallery
        const getModal = this.getModal
        const setRef = this.setRef

        return (
            <Parent {...this.props} >
                <Wapplrpost
                    history={history}
                    style={ps}
                    post={{
                        ...this.state.post,
                        content_extended: this.getContent(this.state.post.content_extended),
                        content_brief: this.getContent(this.state.post.content_brief)
                    }}
                    showauthor={true}
                    avatarstyle={avs}
                    edit={edit}
                    statusname={(deleted) ? statusname : null}
                    publiciconcontent={publiciconcontent}
                    getthumb={getthumb}
                    getthumbstyle={getthumbstyle}
                />
                {(post && post.gallery && post.gallery.length) ?
                    <Wapplrgallery
                        setRef={setRef}
                        type={null}
                        posts={post.gallery}
                        getthumb={getthumb}
                        posttype={'Image'}
                        searchObjectName={'id'}
                        searchData={searchData}
                        style={gs}
                        onClick={openGallery}
                        modal={getModal}
                        feedprops={{ 'thumbheight': { height: '300px' } }}
                    />
                    : null
                }
            </Parent>
        )
    }
})
