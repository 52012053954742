import { routes as iroutes } from './services/routes'
import { configroutes as iconfigroutes } from './services/configroutes'
import content from './contents/content'
import content_edit from './contents/content_edit'
import content_new from './contents/content_new'
import userpagecontent from './contents/content_user'
import { contents as icontents } from './services/contents'
import { editdata as ieditdata } from './services/editdata'
import { fetchs as ifetchs } from './services/fetchs'
import { requests as irequests } from './services/requests'
import { userpageroutes as iuserpageroutes } from './services/userpage_routes'
import { languages as ilanguages } from './languages'

export const configroutes = iconfigroutes
export const routes = iroutes
export const templates = {
    post: content,
    edit: content_edit,
    new: content_new
}
export const userpagetemplates = {
    posts: userpagecontent
}
export const editdata = ieditdata
export const contents = icontents
export const fetchs = ifetchs
export const requests = irequests
export const userpageroutes = iuserpageroutes
export const languages = ilanguages
