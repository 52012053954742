export function fetchs({ postNormalise, defaultRequest, name }) {

    return {
        savepost: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                postNormalise({ data })
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/save'
                })
            }
        },
        deletepost: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/delete'
                })
            }
        },
        duplicatepost: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                postNormalise({ data })
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/new'
                })
            }
        },
        newpost: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                postNormalise({ data })
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/new'
                })
            }
        },
        banpost: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/ban'
                })
            }
        },
        approvepost: function({ getHelpers, success, fail, history, replacefromstate }) {
            return async function(data, resetForm, invalidateForm, refs) {
                await defaultRequest({
                    getHelpers,
                    success,
                    fail,
                    history,
                    replacefromstate,
                    data,
                    resetForm,
                    invalidateForm,
                    refs,
                    link: '/api/' + name + '/approve'
                })
            }
        }
    }
}
